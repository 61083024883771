import React from 'react'

const PrivacyAndPolicy = () => {
    return (
        <div className='p-2'>
            <p className='text-center text-xl font-semibold mt-3 lg:font-bold'>Privacy & Policy - Sankalp Social Trust</p>
            <div className='m-2 md:m-8 lg:m-16'>
                <p className='font-semibold'>Information Gathering</p>
                <div className='mt-3'>
                    <p>1. Sankalp Social Trust collects information from the users in a number of ways, for example when the user:</p>
                    <ul className='ml-4 mt-3 list-disc'>
                        <li>Makes a donation</li>
                        <li>Signs up for a mambership</li>
                        <li>Signs up to stay updated</li>
                    </ul>
                </div>
                <div className='mt-4'>
                    <p>2. While forwarding a donation for Sankalp Social Trust the well-wishers have to submit some personal information as it would help us ensuring genuine contributions:</p>
                    <ul className='ml-4 mt-3 list-disc'>
                        <li>Your name</li>
                        <li>Your email and mailing address</li>
                        <li>Your mobile number</li>
                        <li>Your payment processing details</li>
                        <li>Any other data as required</li>
                    </ul>
                </div>
                <div className='mt-4'>
                    3. Sankalp Social Trust does not collect or record the user’s personal information unless he/she chooses to provide it.

                </div>

                {/* -------------------------------------------------------------------------------------------------------- */}
                <p className='font-semibold mt-5'>Use of Personal Information</p>
                <p className='mt-3'>1. General browsing of Sankalp Social Trust website is anonymous and it does not register the user’spersonal information except the time, date and place of visits and the name of internet service provider. This data is used only for statistics and diagnosis.</p>
                <p className='mt-3'>2. By signing up for various services offered by Sankalp Social Trust, the user explicitly authorizes us to collect information based on the user’s usage. The information is used to help provide a better experience to the user and is used as per the user’s specified instructions.</p>
                <p className='mt-3'>3. Sankalp Social Trust keeps the user information strictly confidential and this information is secured safely. All relevant information collected through Sankalp Social Trust website is handled and used by internal and/or authorized officials only. It is nevershared with any external agencies or third party individuals.</p>
                <p className='mt-3'>4. Sankalp Social Trust uses the information givento it in the following ways:</p>
                <ul className='ml-4 mt-3 list-disc'>
                    <li>To keep an accurate record of all the donations received</li>
                    <li>To make sure the user is receiving the most appropriate and relevant information</li>
                    <li>To find out more about the people who are visiting the Sankalp Social Trust website, donating</li>
                </ul>


                <p className='font-semibold mt-5'>Privacy of e-mail lists</p>
                <div className='mt-3'>
                    <p>Individuals who join Sankalp Social Trust mailing lists via its website or through its campaigning engagements are added to its email database. Sankalp Social Trust does not sell, rent, loan, trade, or lease the addresses on our lists to anyone.</p>
                </div>


                <p className='font-semibold mt-5'>Cookie Policy</p>
                <div className='mt-3'>
                    <p className='mt-3'>1. Cookies are pieces of electronic information which will be sent by Sankalp Social Trust when a user visitsthe website. These will be placed in the hard disk of the user’s computer and enable Sankalp Social Trust to recognise the user when he/she visits the website again.</p>
                    <p className='mt-3'>2. The user can configure his/her browser so that it responds to cookies the way he/she deems fit. For example, you make want to accept all cookies, reject them all or get notified when a cookie is sent. The users may check their browser’s settings to modify cookie behaviour as per individual behaviour.</p>
                    <p className='mt-3'>3. If a user disables the use of cookies on the web browser, or removes or rejects specific cookies from Sankalp Social Trust swebsite or linked sites then he/she may not be able to use the website as it is intended.</p>
                </div>
                <p className='font-semibold mt-5'>Payment Gateway</p>
                <div className='mt-3'>
                    <p className='mt-3'>1. Sankalp Social Trust uses well-recognised and proven technology for payments. Payment information is transferred by the use of an SSL connection which offers the highest degree of security that the donor’s browser is able to support.</p>
                    <p className='mt-3'>2. Several layers of built-in security, including an advanced firewall system, encryption of credit card numbers, and use of passwords, protect the collected information.</p>
                </div>
                <p className='font-semibold mt-5'>External Web Services</p>
                <div className='mt-3'>
                    <p className='mt-3'>1. Sankalp Social Trust uses a number of external web services on its site to display content within its web pages. For example, to display video it uses YouTube. As with the social media buttons, Sankalp Social Trust cannot prevent these sites, or external domains, from collecting information on the user’s consumption of the content embedded on its site.</p>
                    <p className='mt-3'>2. Sankalp Social Trust website contains links to other websites for the benefit of its visitors. This Privacy Policy does not apply to such other websites.</p>
                    <p className='mt-3'>3. Sankalp Social Trust is not expressly or impliedly responsible for, or liable to any loss or damage caused to a user by the collection, use and retention of Personal Information by such website in any manner whatsoever. It is important that the users review the privacy policies of all websites they visit before disclosing any information to such websites.</p>
                </div>

                <p className='font-semibold mt-5'>Changes to Privacy Policy</p>
                <div className='mt-3'>
                    <p className='mt-3'>1. As and when the need arises, Sankalp Social Trust may alter its privacy policy in accordance with the latest technology and trends. It will provide you with timely notice of these changes. The users may reach out to Sankalp Social Trust if they have any queries about any changes made to its practices.</p>
                    <p className='mt-3'>2. If you have any questions at all about Sankalp Social Trust privacy policy, please write to us at: sankalpsocialtrustsdr@gmail.com</p>

                </div>

                <p className='font-semibold mt-5 text-lg text-gray-600'>Refund and Cancellation Policy</p>
                <p className='mt-3'>Welcome to this web-site of SANKALP SOCIAL TRUST. We make public our policy on refund and cancellation of donations received for the social cause on payment gateway as under:-</p>
                <ul className='ml-4 mt-3 list-disc'>
                    <li>No refund/cancellation for the donated amount by any donor will not be entertained, the online donations through the online payment gateway.</li>
                    <li>No cash or refund of money will be allowed.</li>
                    <li>Once received the donation for a cause will not be refunded to the donor. No cancellation to be made. The donation will be used for the community development, children education, feed hunger, clothes distribution for poor and social work.</li>
                </ul>
                <p className='font-semibold mt-5 text-lg text-gray-600'>Terms and Conditions</p>
                <p className='mt-3'>Use of this site is provided by SANKALP SOCIAL TRUST subject to the following Terms and Conditions:</p>
                <ul className='ml-4 mt-3 list-disc'>
                    <li>SANKALP SOCIAL TRUST  reserves the rights to change these terms and conditions at any time by posting changes online. Your continued use of this site after changes are posted constitutes your acceptance of this agreement as modified. You agree to use this site only for lawful purposes, and in a manner which does not infringe the rights, or restrict, or inhibit the use and enjoyment of the site by any third party.</li>
                    <li>This site and the information, names, images, pictures, logos regarding or relating to SANKALP SOCIAL TRUST are provided “as is” without any representation or endorsement made and without warranty of any kind whether express or implied. In no event will SANKALP SOCIAL TRUST be liable for any damages including, without limitation, indirect or consequential damages, or any damages whatsoever arising from the use or in connection with such use or loss of use of the site, whether in contract or in negligence.</li>
                    <li>SANKALP SOCIAL TRUST  does not warrant that the functions contained in the material contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the server that makes it available are free of viruses or bugs or represents the full functionality, accuracy and reliability of the materials.</li>
                </ul>

                <p className='font-semibold mt-5 text-lg text-gray-600'>Copyright restrictions:</p>
            <ul className='ml-4 mt-3 list-disc'>
                <li >Commercial use or publication of all or any item displayed is strictly prohibited without prior authorization from SANKALP SOCIAL TRUST. Nothing contained here in shall be construed as conferring any license by SANKALP SOCIAL TRUST to use any item displayed.</li>
                <li >Documents may be copied for personal use only on the condition that copyright and source indications are also copied, no modifications are made and the document is copied entirely.</li>
                <li >SANKALP SOCIAL TRUST takes no responsibility for the content of external Internet sites. Other websites that we link to are owned and operated by third parties and SANKALP SOCIAL TRUST has no control over them. The fact that we include links to other websites does not mean that SANKALP SOCIAL TRUST approves of or endorses any other third party website or the content of that website. We accept no liability for any statements, information, products or services that are published on or are accessible through any websites owned or operated by third parties.</li>
                <li >Any communication or material that you transmit to, or post on, any public area of the site including any data, questions, comments, suggestions, or the like, is, and will be treated as, non-confidential and nonproprietary information. If there is any conflict between these terms and conditions and rules and/or specific terms of use appearing on this site relating to specific material then the latter shall prevail.</li>
                <li >These terms and conditions shall be governed and construed in accordance with the laws of India.</li>
                <li >If these terms and conditions are not accepted in full, the use of this site must be terminated immediately.</li>
            </ul>

         <div className='mt-10 font-semibold text-sm'>
         <p className=' text-gray-600'>REGISTRATION NUMBER : 202400933010232 </p>
            <p className=' text-gray-600'>NGO DARPAN UNIQUE ID : UP/2024/0472580 </p>
            <p className=' text-gray-600'>PAN NUMBER : ABKTS4994E</p>
         </div>

            </div>
        </div>

    )
}

export default PrivacyAndPolicy